import React, { Component } from "react"
import { Modal, Container, Row, Col, Button } from 'react-bootstrap'
import { connect } from "react-redux";

import PageBanner from "../../common/page/PageBanner";
import CountyNotes from "../../common/page/CountyNotes";

import SelectField from "../../common/form/SelectField";

import SearchResultsDetails from "./SearchResultsDetails";
import SearchResultsTable from "./SearchResultsTable";

import { loadDocuments, displayDetails, displayTaxLienDetails, searchByTaxLien, setSelectedSearchType, SpecialSearchTaxLienKey } from "../../../redux/actions/searchActions";

import { caretSquareDownIcon, caretSquareUpIcon, infoSmallIcon, fileDownloadSmallIcon, backwardSmallIcon, minusIcon, filterIcon } from "../../../img/font_awesome/font_awesome"

import { searchResultsSort, searchResultsPrint } from "../../../redux/actions/searchActions";
import Thumbnails from "../../image/Thumbnails";
import TaxLinks from "../../external/TaxLinks";
import GisLinks from "../../external/GisLinks";
import Alert from '../../common/modal/Alert';
import { refreshUsageBalance, refreshUsageBalanceFromContent } from "../../../redux/actions/otherLandLinkActions";

import { LOADING_STATUES } from "../../constants/constants";
import Loading from '../../common/modal/Loading';

import NotableDates from '../../home/NotableDates';

// Child state actions.
const ALERT_MESSAGE = 'MESSAGE';

const matchDispatchToProps = (dispatch) => {
    return { 
      refreshUsageBalanceFromContent: (content) => dispatch(refreshUsageBalanceFromContent(content)),
      refreshUsageBalance: (userId) => dispatch(refreshUsageBalance(userId)),
      searchResultsSort: (sort, currentSearch, history, callback) => dispatch(searchResultsSort(sort, currentSearch, history, callback)),
      filteredResultsSort: (sortKey, sortDirection) => dispatch({ type: 'SORT_FILTERED_DOCUMENTS', sortKey, sortDirection }),
      setSearchResultsSort: (sortKey, sortDirection) => dispatch({ type: 'SET_SELECTED_SEARCH_RESULTS_SORT', sort: {sortKey, sortDirection }}),
                                                      
      searchResultsPrint: (sort, currentSearch, history, callback) => dispatch(searchResultsPrint(sort, currentSearch, history, callback)),
      loadDocuments: (searchCriteria, history, link, callback) => dispatch(loadDocuments(searchCriteria, history, callback, link)),
      searchByTaxLien: (searchCriteria, history, callback) => dispatch(searchByTaxLien(searchCriteria, history, callback)),
      selectDetail: (id, description, callback) => dispatch(displayDetails(id, description, callback)),
      selectTaxLienDetails: (id, description, callback) => dispatch(displayTaxLienDetails(id, description, callback)),
      resetSearchSequence: () => dispatch({ type: 'RESET_SEARCH_SEQUENCE'}),
      rewindSearch: () =>  dispatch({ type: 'REWIND_SEARCH_SEQUENCE'}),
      paginate: (pageNumber) => dispatch({ type: 'SET_SELECTED_SEARCH_RESULTS_PAGE', pageNumber }),
      setSelectedSearchType: (searchType) => dispatch(setSelectedSearchType(searchType)),
      showFilteredResults: (searchResults) => dispatch({ type: 'SHOW_FILTERED_SEARCH_RESULTS', searchResults }),  // This is the search results table
      applySearchResultsFilter: (applyFilter) => dispatch({ type: 'APPLY_SEARCH_RESULTS_FILTER', applyFilter})
    }
}

const mapStateToProps = state => {
    return {
        searchResults: state.searchResultsReducer,
        searchResultsDetails: state.searchDetailsReducer,
        bookLabel: state.countyConfigCommonReducer.bookLabel,
        showDownloadResultsButton: state.countyConfigSearch2Reducer.downloadResults,
        sideNavigationOpen: state.sideNavigationReducer,
        currentSearch: state.searchHistoryReducer.searchHistoryList[0],
        currentUser: state.currentUserReducer,
        pageNumber: state.searchResultsPaginationReducer,
        tableLoading: state.searchResultsTableLoadingReducer,
        sortKey: state.searchResultsSortReducer.sortKey,
        sortDirection: state.searchResultsSortReducer.sortDirection,
        notableDates: state.notableDatesReducer,
        searchSequence: state.searchSequenceStartedReducer,
        searchSequenceSelectedDocumentId: state.searchResultsTableSelectedRowReducer,
        filteredDocuments: state.searchResultsFilterReducer,         
        filterApplied: state.searchResultsFilterAppliedFilter    
    };
}

// This is a big class.
// Here are some common search points:
// image window preview ok
// image window accepted charges
// image window ask for buy options

class SearchResults extends Component {
    constructor(props) {
        super(props);

        this.version = 0;

        this.state = {
            // filterApplied: false,
            searchResultsSort: 'docNumber',   // Date is the default sort
            searchResultsSortDirection: 'ASC',
            imagingWindows: this.createImagingWindowsWindowState('', false),
            taxLinks: this.createTaxLinksWindowState('', false),
            gisLinks: this.createGisLinksWindowState('', false),
            showNotableDatesModal: false,
            notableDatesLeft: 600,
            notableDates: {},
            alertWindow: {
                callback: null,
                message: '',
                show: false,
                type: ''
            },
            loadingStatus: ""
        };
    }

    handleChange = (event) => {
        this.setState(
            {
                searchResultsSort: event.target.value
            },
            function() {   // callback function after the state is set
                this.handleSort();
            }
        )
    }

    handleClick = () => {
        this.setState(
            {
                searchResultsSortDirection: this.state.searchResultsSortDirection === 'ASC'? 'DESC' : 'ASC'
            },
            function() {   // callback function after the state is set
                this.handleSort();
            }
        )
    }

    handleSort = () => {
        if (this.props.filterApplied === true) {
            this.props.filteredResultsSort(this.state.searchResultsSort, this.state.searchResultsSortDirection);
            this.props.setSearchResultsSort(this.state.searchResultsSort, this.state.searchResultsSortDirection);
        } else if (this.showLoading() === true) {
            const { loadingStatus, imagingWindows, alertWindow, ...request } = this.state; // remove UI only fields before submitting
            const request1 = Object.assign(request, { pageNumber: this.props.pageNumber });
		    this.props.searchResultsSort(request1, this.props.currentSearch, this.props.history, this.hideLoading);
        }
	}

    addShowLoading = (newState) => {
        newState.loadingStatus = LOADING_STATUES.LOADING;
    }

    showLoading = () => {
        if (this.state.loadingStatus === "") {
          this.setState({
            loadingStatus: LOADING_STATUES.LOADING
          })
          return true;
        } 
        return false;
      }

    hideLoading = (message, status) => {
          this.setState({
            loadingStatus: ""
          })
      }

    // May return null if not found.
    getSelectedSearchResult = () => {
        if (!this.props.searchResults || (this.props.searchResultsDetails === 0)) {
            return null;
        }

        let searchResult = this.props.searchResults.searchResultList.find((searchResult) => {
            return this.props.searchResultsDetails == searchResult.docId;
        });

        return searchResult;
    }

    // Can return null.
    getSelectedSearchResultInfo = () => {
        let searchResult = this.getSelectedSearchResult();

        if (!searchResult) {
            return null;
        }

		let info = { 
            documentType: searchResult.docType, 
            documentNumber: searchResult.docNumber,
            documentKey: searchResult.docType + searchResult.docNumber,
            instrumentCode: searchResult.instrumentCode
        };

        return info;
    }

    handleImageClick = () => {
        let info = this.getSelectedSearchResultInfo();

        if (!info) {
            alert("Select a document.");
            return; 
        }

        this.setState( {
            imagingWindows: this.createImagingWindowsWindowState(info, true)
        } );
    }

    handleImageComplete = () => {
        this.setState( {
            imagingWindows: this.createImagingWindowsWindowState(null, false)
        } );
    }

    handleTaxClick = () => {
        if (this.props.searchResultsDetails === 0) {
            alert("Select a document.");
            return; 
        }
        
        this.setState( {
            taxLinks: this.createTaxLinksWindowState(this.props.searchResultsDetails, true)
        } );
    }

    hideTaxLinks = () => {
        this.setState( {
            taxLinks: this.createTaxLinksWindowState(null, false)
        } );
    }

    handleGisClick = () => {
        if (this.props.searchResultsDetails === 0) {
            alert("Select a document.");
            return; 
        }
        
        this.setState( {
            gisLinks: this.createGisLinksWindowState(this.props.searchResultsDetails, true)
        } );
    }

    hideGisLinks = () => {
        this.setState( {
            gisLinks: this.createGisLinksWindowState(null, false)
        } );
    }

    createImagingWindowsWindowState = (searchResult, show) => {
        return {
            docKey: searchResult ? searchResult.documentKey : '',
            instCode: searchResult ? searchResult.instrumentCode : '',
            show: show
        }
    }

    createTaxLinksWindowState = (selectedDocId, show) => {
        return {
            docId: selectedDocId ? selectedDocId : '',
            show: show
        }
    }

    createGisLinksWindowState = (selectedDocId, show) => {
        return {
            docId: selectedDocId ? selectedDocId : '',
            show: show
        }
    }
    
    createAlertWindowParams = (show, message, callback, type) => {
        if (show) {
            return {
                callback: callback,
                message: message,
                show: show,
                type: type ? type : ALERT_MESSAGE
            };
        } else {
            return {
                callback: null,
                message: '',
                show: show,
                type: ALERT_MESSAGE
            };
        }
    }

    addAlert = (state, alertWindowParams) => {
		state.alertWindow = alertWindowParams;
    }
	
    showAlert = (alertWindowParams) => {
		this.setState({
		  alertWindow: alertWindowParams
		})
	  }
	
	hideAlert = () => {
        // After the alert is confirmed, close the alert and pay for the order.
        let callback = this.state.alertWindow.callback;
        let params = this.createAlertWindowParams(false);
		this.setState({
			    alertWindow: params
		    },
            () => { if (callback) callback(); }
        );
	}

    printResults = (event) => {
        event.preventDefault();
        if (this.showLoading() === true) {
            const { loadingStatus, imagingWindows, alertWindow, ...request } = this.state; // remove UI only fields before submitting
            const request1 = Object.assign(request, { pageNumber: -1 });
		    this.props.searchResultsPrint(request1, this.props.currentSearch, this.props.history, this.hideLoading);
        }
    }
     
    showOriginalResultList = (event) => {
        event.preventDefault();

        let request = {}
        this.props.searchSequence.displayCriteria.forEach((criteria) => {
            request[criteria.key] = criteria.value;
        })
       
        request.updateHistory = true;
        request.sortKey = this.props.searchSequence.sortKey; 
        request.sortDirection = this.props.searchSequence.sortDirection; 
        
        if (this.showLoading() === true) {
            if (this.props.searchSequence.pageNumber > 1) {
                request.pageNumber = this.props.searchSequence.pageNumber;
                this.props.paginate(request.pageNumber);
            }

            this.props.resetSearchSequence();
            this.props.rewindSearch();
            if (this.props.searchSequence.link === 'search/searchByTaxLien') {
                this.props.setSelectedSearchType(SpecialSearchTaxLienKey);
                this.props.searchByTaxLien(request, this.props.history, this.selectTaxLienDetails);
            } else {
                this.props.setSelectedSearchType(this.props.searchSequence.type);
                this.props.loadDocuments(request, this.props.history, this.props.searchSequence.link, this.selectDetail);
            }
        }
    }

    selectDetail = () => {
        this.props.selectDetail(this.props.searchSequenceSelectedDocumentId, '', this.hideLoading);
    }

    selectTaxLienDetails = () => {
        this.props.selectTaxLienDetails(this.props.searchSequenceSelectedDocumentId, '', this.hideLoading);
    }

    showNotableDates = (event) => {
        event.preventDefault();
        let notableDatesLeft = Math.ceil(event.clientX / 3);
        this.setState({notableDatesLeft, showNotableDatesModal : true});
    }

    hideNotableDates = (event) => {
        event.preventDefault();
        this.setState({showNotableDatesModal : false});
    }

    handleApplyFilter = (event) => { 
        event.preventDefault();      
        if (this.props.filterApplied === false) {
            this.props.applySearchResultsFilter(true);
            this.props.showFilteredResults(this.props.filteredDocuments);
            this.props.paginate(1);
        } else if (this.showLoading() === true) {
            this.props.applySearchResultsFilter(false);
            const { loadingStatus, imagingWindows, alertWindow, ...request } = this.state; // remove UI only fields before submitting
            const request1 = Object.assign(request, { pageNumber: this.props.pageNumber });
		    this.props.searchResultsSort(request1, this.props.currentSearch, this.props.history, this.hideLoading);
        }
    }
    
    render() {
     
        let formTemplateMarginLeft = 60
        if (this.props.sideNavigationOpen) {
            formTemplateMarginLeft = 270
        }

        let buttonIcon = caretSquareDownIcon;
        if (this.state.searchResultsSortDirection === 'ASC') {
          buttonIcon = caretSquareUpIcon;
        }

        let sortOptions = [{ code: "docNumber", description: "Document Number"}, {code: "instrument", description: "Instrument"}, {code: "recordedDate", description: "Recorded Date"}, { code: "book", description: this.props.bookLabel }];
		sortOptions.sort((a, b) => 
			{ 
				if (a.description > b.description) {
					return 1;
			  	} else if (a.description < b.description) {
					return -1;
			  	}
			  	return 0;
			}
		)

        let sortByFormGroup =  { layout: 'horizontal', id: 'searchResutlsSort', label: 'Sort by', fieldType: 'select', value: this.props.sortKey, size: "sm", options: sortOptions }
        
        let currentSearchLongDescription = this.props.currentSearch ? this.props.currentSearch.longDescription : ""
        let currentSearchCriteria = this.props.currentSearch ? this.props.currentSearch.displayCriteria : []
        let selectButton =  <Button variant="primary" size="sm" onClick={this.handleClick} disabled={this.props.tableLoading}> {buttonIcon} </Button>

        let pageButtons = [];
        if (this.props.searchSequence !==  null) {
            pageButtons.push({ label: 'Back to Search Results', icon:backwardSmallIcon, onClickAction: this.showOriginalResultList, className: 'btn btn-secondary btn-sm' });
        }
        pageButtons.push({ label: 'Notable Dates', icon:infoSmallIcon, onClickAction: this.showNotableDates, className: 'btn btn-secondary btn-sm' });


        let filter = <Button variant='secondary' size="sm" style={{marginLeft: 2, marginBottom: 4}} onClick={this.handleApplyFilter}>{filterIcon} Filter Selected</Button>
        if (this.props.filterApplied === true) {
            filter = <Button variant='secondary' size="sm" style={{marginLeft: 2, marginBottom: 4}} onClick={this.handleApplyFilter}>{minusIcon} Remove Filter</Button>
        }

        return (
            <div style={{ marginTop: 4, marginLeft: formTemplateMarginLeft, marginRight: 20, marginBottom: 4,  paddingBottom: 4, borderTop: '1px solid lightGray', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.55), 0 6px 20px 0 rgba(0, 0, 0, 0.54)" }} >
                <CountyNotes />
                <PageBanner pageHeading={currentSearchLongDescription} searchCriteria={currentSearchCriteria}  buttons={pageButtons}   />
                <Container fluid="true" >
                    <Row style={{marginRight: 1}}>
                        <Col lg={7} style={{paddingRight: 0}}>
                            { filter }                              
                            {
                                this.props.showDownloadResultsButton &&
                                <>&nbsp;<Button variant="secondary" size="sm" style={{marginLeft: 2, marginBottom: 4}} onClick={this.printResults} >{fileDownloadSmallIcon} Download</Button></>
                            }
                            <SelectField size="sm" formGroup={sortByFormGroup} selectButton={selectButton} onChange={this.handleChange} disabled={this.props.tableLoading}/>
                            <SearchResultsTable/>
                        </Col>
                   
                        <Col lg={5} style={{paddingTop: 2, paddingBottom: 4}}>
                            <SearchResultsDetails handleImageClick={this.handleImageClick} 
                                                  handleTaxClick={this.handleTaxClick} 
                                                  handleGisClick={this.handleGisClick} 
                                                  history={this.props.history}/>
                        </Col>
                    </Row>
                </Container>
                <Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING} />
                <Thumbnails show={this.state.imagingWindows.show} 
                    docKey={this.state.imagingWindows.docKey} 
                    instCode={this.state.imagingWindows.instCode} 
                    complete={this.handleImageComplete} />
                <TaxLinks show = {this.state.taxLinks.show}
                    docId={this.state.taxLinks.docId}
                    complete={this.hideTaxLinks} />
                <GisLinks show = {this.state.gisLinks.show}
                    docId={this.state.gisLinks.docId}
                    complete={this.hideGisLinks} />
                <Alert show={this.state.alertWindow.show} hideAlert={this.hideAlert} message={this.state.alertWindow.message} type={this.state.alertWindow.type} />
              
                <Modal show={this.state.showNotableDatesModal} onHide={this.hideNotableDates} style={{ left: this.state.notableDatesLeft, top: 0}} aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body style={{ background: '#3674AB', color: 'white', padding: 0 }}>
                        <NotableDates notableDates={this.props.notableDates} cardHeaderClassName='card-header-gray' />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideNotableDates}>Close</Button>
                    </Modal.Footer>
                </Modal>
              
            </div>
        )
    }
}

export default connect(mapStateToProps, matchDispatchToProps)(SearchResults);