export const trimAccountFields = (account) => {
    let trimmedAccount = {};

    // Account Name
    if (account.name) {
        let name = account.name.trim();
        name = {name};
        trimmedAccount = {...trimmedAccount, ...name};
    }

    // LandLink Account
    if (account.landLinkAccount) {
        let landLinkAccount = account.landLinkAccount.trim();
        landLinkAccount = {landLinkAccount};
        trimmedAccount = {...trimmedAccount, ...landLinkAccount};
    }

    // Company
    if (account.company) {
        let company = account.company.trim();
        company = {company};
        trimmedAccount = {...trimmedAccount, ...company};
    }

    // Address
    if (account.address) {
        let address = account.address.trim();
        address = {address};
        trimmedAccount = {...trimmedAccount, ...address};
    }

    // City
    if (account.city) {
        let city = account.city.trim();
        city = {city};
        trimmedAccount = {...trimmedAccount, ...city};
    }

    // Email
    if (account.email) {
        let email = account.email.trim();
        email = {email};
        trimmedAccount = {...trimmedAccount, ...email};
    }
    return trimmedAccount;
}

// This function is used by different user forms in the app.
// Different forms may or may not contain a certain field. 
// For example, you can't change the username on an existing user, so its form doesn't have username.
// Hence, the checks for undefined to make sure the field is in the user object.
export const validateAccount = (account, validateAccountName = true) => {
    let errors = {};

    // First Name
    if (validateAccountName && account.name !== undefined) {
        if (!account.name) {
            errors["name"] = "Required.";
            return errors;
        }

        if (account.name && account.name.length > 60) {
            errors["name"] = "60 characters max.";
            return errors;
        }
    }

    const landLinkAccount = account.landLinkAccount;

    if(/\D/.test(landLinkAccount)) {
        errors["landLinkAccount"] = "Numeric Account Number";
        return errors;
    }

    // Address
    if (account.address !== undefined) {
        if (account.address && account.address.length > 50) {
            errors["address"] = "50 characters max.";
            return errors;
        }
    }

    // City
    if (account.city !== undefined) {
        if (account.city && account.city.length > 50) {
            errors["city"] = "50 characters max.";
            return errors;
        }
    }

    // Email
    if (account.email !== undefined) {
        if (account.email && account.email.length > 50) {
            errors["email"] = "50 characters max.";
            return errors;
        }
    }

    return errors;
}