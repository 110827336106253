import React from 'react';
import axios from "axios";
import configData from "../../config/config.json";
import usStates from "../../config/us_states.json";
import { connect } from "react-redux";
import FormTemplate from "../common/form/FormTemplate";
import Alert from '../common/modal/Alert';
import { setSelectedControlPanelType, AccountsControlPanelKey } from "../../redux/actions/controlPanelActions";
import { saveAccount } from "../../redux/actions/accountActions";
import Loading from '../common/modal/Loading';
import Confirmation from '../common/modal/Confirmation';
import { formatPhone } from "../common/function/formattingUtils.js";
import { LOADING_STATUES } from "../../components/constants/constants";
import { validateAccount, trimAccountFields } from "../common/function/accountValidation";

const componentName = AccountsControlPanelKey;

const mapStateToProps = (state) => {
	return {
		accountTypes: state.accountTypesReducer.codeDescriptionResultList,
		paymentMethodTypesAll: state.paymentMethodTypesAllReducer.codeDescriptionResultList,
		paymentMethodTypesNonALaCarte: state.paymentMethodTypesNonALaCarteReducer.codeDescriptionResultList,
		accountListFilters: state.accountListFiltersReducer,
		isTorrens : state.landlinkConfigReducer.torrens,
		countyConfigFlatFee: state.countyConfigFlatFeeReducer
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setSelectedControlPanelType: () => dispatch(setSelectedControlPanelType(componentName)),
		saveAccount: (account, callback) => dispatch(saveAccount(account, callback))
	}
}

class AccountEntry extends React.Component {
	state = {
		id: 0,
		name: '',
		flatFeeRateLevel: 0,
		monthlyFee: 0,
		imageChargeMethod: 'DOCUMENT',
		certificateChargeMethod: 'DOCUMENT',
		accountType: 'A_LA_CARTE',
		paymentMethod: 'ESCROW',
		address: '',
		city: '',
		state: '',
		zip: '',
		phone: '',
		email: '',	
		status: 'A',
		landLinkAccount: '',
		lastMonthlyRenewal: '',
		needsMonthlyRenewal: false,
		needsMonthlyRenewalJob: false, 
		postToLandLink: false,
		imageRetrieval: false,
		previewRetrieval: false,
		certificateRetrieval: false,
		certificatePreviewRetrieval: false,
		countyConfigFlatFee: {},
		showDeleteConfirmationDialog: false,
		loadingStatus: '',     // Account has another field called status, so I need to call this field loadingStatus.
		errors: {},
		showAlert: false,
		alertMessage: "",
		redirect: false,
		userReload: false
	  }

	handleChange = (event) => {
		event.preventDefault();

		let value = event.target.value;
		if (event.target.id === 'phone') {
		   value = formatPhone(value, this.state.phone);
		}
		 
		this.setState({
			[event.target.id]: value ? value : (event.target.type === 'number' ? 0 : value),
			errors: {}
		})

		if (this.state.paymentMethod !== 'CREDIT_CARD') {
			this.setState({
				postToLandLink: true
			})
		}
	}
	
	showLoading = () => {
		if (this.state.loadingStatus === "") {
			this.setState({
				loadingStatus: LOADING_STATUES.LOADING
			})
			return true;
		}
		return false;
	}

	hideLoading = (loadingStatus) => {
		if (loadingStatus === 'ERROR') {
			this.setState({
				loadingStatus: ""
			})
		} else {
			this.setState({
				loadingStatus: ""
			})
		}
	}

	handleCancel = (event) => {
		event.preventDefault();
		this.props.history.push('/controlPanel/accounts');
	}

	handleSubmit = (event) => {
		event.preventDefault();

		const trimmedAccountFields = trimAccountFields(this.state);			
		this.setState({...trimmedAccountFields},
			function() { 
				this.validateAndSubmit();
			}
		)
	}

	handleSaveResult = (redirect, errorFound, message) => {
		let alertType = errorFound ? 'WARNING': '';
		this.setState({loadingStatus: "", redirect, alertType}, this.showAlert(message))
	}

	validateAndSubmit = () => {
		let errors = validateAccount(this.state, this.state.id === 0);
		if (errors && Object.keys(errors).length > 0) {
			this.setState({ errors });
			return;
		}

		if (this.showLoading() === true) {
			this.props.saveAccount(this.state, this.handleSaveResult);
		}
	}

	// Delete methods
	handleDeleteAccount = () => {
		if (this.showLoading() === true) {
			let request = { 
				accountId: this.state.id, 
				filterText: this.props.accountListFilters.filterText, 
				includeInactive: 
				this.props.accountListFilters.includeInactive 
			};

			axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/accountDelete", request, { withCredentials: true })
				.then((response) => {
					this.setState({ loadingStatus: "", redirect: true }, this.showAlert(response.data.message));
				}, (error) => {
					
					this.setState({ loadingStatus: "", redirect: false }, this.showAlert(error.message));
				});
		}
	}

	showDeleteConfirmation = (event) => {
		event.preventDefault();
		this.setState({
			showDeleteConfirmationDialog: true
		})
	}

	hideDeleteConfirmation = () => {
		this.setState({
			showDeleteConfirmationDialog: false
		})
	}
	// Delete methods - End

	handleChangeAccountStatus = (event) => {
		event.preventDefault();
		if (this.showLoading() === true) {
			axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/accountChangeStatusSingle", { accountId: this.state.id, filterText: this.props.accountListFilters.filterText, includeInactive: this.props.accountListFilters.includeInactive }, { withCredentials: true })
				.then((response) => {
					this.setState({ loadingStatus: "", status: response.data.content.status }, this.showAlert(response.data.message));
				}, (error) => {
					this.setState({ loadingStatus: "" }, this.showAlert(error.message));
				});
		}
	}

	handleMonthlyRenewal = (event) => {
		event.preventDefault();

		if (this.showLoading() === true) {
			axios.post(configData.LANDSHARK_SERVER_URL + "controlPanel/runMonthlyRenewal", { accountId: this.state.id, filterText: this.props.accountListFilters.filterText, includeInactive: this.props.accountListFilters.includeInactive }, { withCredentials: true })
				.then((response) => {
					const content = response.data.content;
					let newState = {
						...this.getStateFromContent(content),
						loadingStatus: ""
					};

					this.setState(newState, this.showAlert(response.data.message));
				}, (error) => {
					this.setState({ loadingStatus: ""}, this.showAlert(error.response.data.message));
				});
		}
	  }
	
	componentDidMount() {
		this.props.setSelectedControlPanelType();
	
		// new account
		if (this.props.match.params.id === '0') {
			return;
		}

		axios.get(configData.LANDSHARK_SERVER_URL + "controlPanel/account/" + this.props.match.params.id, { withCredentials: true })
		.then(
			(response) => {
				const content = response.data.content;
				let newState = this.getStateFromContent(content);

				this.setState(newState);
			},
			(error) => {
				console.log(error);
			}
		);
	}

	getStateFromContent = (content) => {
		return {
			id: content.id,
			name: content.name,
			accountType: content.accountType,
			paymentMethod: content.paymentMethod,
			monthlyFee: content.monthlyFee,
			imageChargeMethod: content.imageChargeMethod,
			certificateChargeMethod: content.certificateChargeMethod,
			address: content.address,
			city: content.city,
			state: content.state,
			zip: content.zip,
			phone: content.phone,
			email: content.email,
			status: content.status,
			landLinkAccount: content.landLinkAccount,
			lastMonthlyRenewal: content.lastMonthlyRenewal,
			needsMonthlyRenewal: content.needsMonthlyRenewal,
			needsMonthlyRenewalJob: content.needsMonthlyRenewalJob, 
			postToLandLink: content.postToLandLink,
			imageRetrieval: content.imageRetrieval,
			previewRetrieval: content.previewRetrieval,
			certificateRetrieval: content.certificateRetrieval,
			certificatePreviewRetrieval: content.certificatePreviewRetrieval,
			flatFeeRateLevel: content.flatFeeRateLevel,
			countyConfigFlatFee: content.countyConfigFlatFee,
			userReload: content.userReload,
			alertType: ''
		};
	}

	createRateOption = (index, rate) => {
		let item = 'document';

		if (this.state.imageChargeMethod !== 'DOCUMENT') {
			item = "page";
		}

		if (rate.unlimitedNumberOfPages) {
			return {code: index, disabled: !rate.option, description: 'Unlimited ' + item + 's for $' + rate.cost};
		} else {
			let itemDescription = (rate.numberOfPages == 1) ? item : item + 's';
			return {code: index, disabled: !rate.option, description: rate.numberOfPages + ' ' + itemDescription + 
				' for $' + rate.cost};
		}
	}

	createRateOptions = () => {
		let flatFeeRates = [{code: 0, description: "Free"}];

		const countyConfigFlatFee = this.state.countyConfigFlatFee && Object.keys(this.state.countyConfigFlatFee).length > 0 ? this.state.countyConfigFlatFee : this.props.countyConfigFlatFee;
	
		// County doesn't charge for images, so the only available flat fee option is Free
		if (countyConfigFlatFee.chargingImages === false) {
			return flatFeeRates;
		}

		if (countyConfigFlatFee.rates) {
			for (let i = 0; i < 5; ++i) {
				flatFeeRates.push(this.createRateOption(i+1, countyConfigFlatFee.rates[i]));
			}
		}
		
		return flatFeeRates;
	}

	showAlert = (alertMessage) => {
		this.setState({
		  showAlert: true,
		  alertMessage
		})
	  }
	
	hideAlert = () => {
		this.setState({
			showAlert: false,
			alertMessage: '',
			alertType: ''
		})
	
		if (this.state.redirect) {
			this.props.history.push('/controlPanel/accounts');
		}
	}

	render() {

		let paymentMethodOptions = this.props.paymentMethodTypesAll;
		if (this.state.accountType !== 'A_LA_CARTE') {
			paymentMethodOptions = this.props.paymentMethodTypesNonALaCarte;
		} 

		let disabledFlag = this.state.status !== 'A' && this.state.id !== 0;

		// Not disabled, show a dropdown.  Otherwise, show a text field.
		let accountTypeField = { id: 'accountType', label: 'Account Type', fieldType: 'select', value: this.state.accountType, options: this.props.accountTypes, style: { minWidth: 220 } }
		let paymentMethodField = { id: 'paymentMethod', label: 'Payment Method', fieldType: 'select', value: this.state.paymentMethod, options: paymentMethodOptions }
		let userReloadField =  { id: 'userReload', label: 'Reload by Account Admin', fieldType: 'select', hideField: (this.state.accountType !== 'FLAT_FEE' ||  this.state.flatFeeRateLevel === 0 ||  this.state.flatFeeRateLevel == "0" ), value: this.state.userReload }
		let postToLandLinkField =  { id: 'postToLandLink', label: 'Post to LandLink', fieldType: 'select', hideField: this.state.paymentMethod !== 'CREDIT_CARD', value: this.state.postToLandLink}
		
		let imageChargeMethodField = { id: 'imageChargeMethod', label: 'Charge Image by', fieldType: 'select', value: this.state.imageChargeMethod, options: [{ code: "DOCUMENT", description: "Document"}, {code: "PAGE", description: "Page" }]  }
		let imageRetrievalField = { id: 'imageRetrieval', label: 'Image Retrieval', fieldType: 'select', value: this.state.imageRetrieval }					 					
		let previewRetrievalField =  { id: 'previewRetrieval', label: 'Preview Retrieval', fieldType: 'select', value: this.state.previewRetrieval  }
		
		let certificateChargeMethodField = { id: 'certificateChargeMethod', label: 'Charge Certificate by', fieldType: 'select', value: this.state.certificateChargeMethod, options: [{ code: "DOCUMENT", description: "Document"}, {code: "PAGE", description: "Page" }]  }
		let certificateRetrievalField = { id: 'certificateRetrieval', label: 'Certificate Retrieval', fieldType: 'select', value: this.state.certificateRetrieval }					 					
		let certificatePreviewRetrievalField =  { id: 'certificatePreviewRetrieval', label: 'Preview Retrieval', fieldType: 'select', value: this.state.certificatePreviewRetrieval  }
		
		let lastMonthlyRenewalField = { id: 'lastMonthlyRenewal', label: 'Last Monthly Renewal', fieldType: 'text', value: this.state.lastMonthlyRenewal, disabled:true }
		let needsMonthlyRenewalField = { id: 'needsMonthlyRenewal', label: 'Needs Monthly Renewal', fieldType: 'text', value: this.state.needsMonthlyRenewal, disabled:true }
		let needsMonthlyRenewalJobField = { id: 'needsMonthlyRenewalJob', label: 'Needs Monthly Renewal Job', fieldType: 'text', value: this.state.needsMonthlyRenewalJob, disabled:true }

		if (disabledFlag) {
			accountTypeField = { id: 'accountType', label: 'Account Type', fieldType: 'text', value: this.state.accountType, disabled:true }
			paymentMethodField = { id: 'paymentMethod', label: 'Payment Method', fieldType: 'text', value: this.state.paymentMethod, disabled:true }
			userReloadField = { id: 'userReload', label: 'Reload by Account Admin', fieldType: 'text', hideField: (this.state.accountType !== 'FLAT_FEE' ||  this.state.flatFeeRateLevel === 0 ||  this.state.flatFeeRateLevel == "0"), value: this.state.userReload === true ? "Yes" : "No", disabled:true }
			postToLandLinkField = { id: 'postToLandLink', label: 'Post to LandLink', fieldType: 'text', hideField: this.state.paymentMethod !== 'CREDIT_CARD', value: this.state.postToLandLink === true ? "Yes" : "No",  disabled:true }
			imageChargeMethodField = { id: 'imageChargeMethod', label: 'Charge Image by', fieldType: 'text', value: this.state.imageChargeMethod,  disabled:true }
			imageRetrievalField = { id: 'imageRetrieval', label: 'Image Retrieval', fieldType: 'text', value: this.state.imageRetrieval === true ? "Yes" : "No",  disabled:true }
			previewRetrievalField = { id: 'previewRetrieval', label: 'Preview Retrieval', fieldType: 'text', value: this.state.previewRetrieval === true ? "Yes" : "No",  disabled:true }
			certificateChargeMethodField = { id: 'certificateChargeMethod', label: 'Charge Certificate by', fieldType: 'text', value: this.state.certificateChargeMethod,  disabled:true }
			certificateRetrievalField = { id: 'certificateRetrieval', label: 'Certificate Retrieval', fieldType: 'text', value: this.state.certificateRetrieval === true ? "Yes" : "No",  disabled:true }
			certificatePreviewRetrievalField = { id: 'certificatePreviewRetrieval', label: 'Preview Retrieval', fieldType: 'text', value: this.state.certificatePreviewRetrieval === true ? "Yes" : "No",  disabled:true }
		} else{
			if (!this.state.imageRetrieval || this.state.imageRetrieval === 'false') {
				previewRetrievalField = { id: 'previewRetrieval', label: 'Preview Retrieval', fieldType: 'text', value: this.state.previewRetrieval === true ? "Yes" : "No", disabled: true }
			}
			if (!this.state.certificateRetrieval || this.state.certificateRetrieval === 'false') {
				certificatePreviewRetrievalField = { id: 'certificatePreviewRetrieval', label: 'Preview Retrieval', fieldType: 'text', value: this.state.certificatePreviewRetrieval === true ? "Yes" : "No", disabled: true }
			}
		}

		let flatFeeRateOptions = this.createRateOptions();
		
		let landlinkAccountRequired = (this.state.accountType === 'A_LA_CARTE' && this.state.paymentMethod !== 'CREDIT_CARD') 
							|| (this.state.accountType === 'FLAT_FEE' &&  this.state.flatFeeRateLevel !== "0" &&  this.state.flatFeeRateLevel !== 0) 
							|| (this.state.paymentMethod === 'CREDIT_CARD' && (this.state.postToLandLink === true || this.state.postToLandLink === 'true')) 
							
		let formGroupRows = [
			{
				formGroups: [accountTypeField,
					paymentMethodField,
					{ id: 'flatFeeRateLevel', label: 'Monthly Rate', fieldType: 'select', hideField: this.state.accountType !== 'FLAT_FEE', options: flatFeeRateOptions, value: this.state.flatFeeRateLevel },
					{ id: 'monthlyFee', label: 'Monthly Fee', fieldType: 'number', numberType: 'decimal', hideField: this.state.accountType === 'FLAT_FEE', value: this.state.monthlyFee, disabled: disabledFlag },
					userReloadField]
			},
			{
				formGroups: [{ id: 'landLinkAccount', label: 'LandLink Account #', fieldType: 'text', maxLength: 10, value: this.state.landLinkAccount, required: landlinkAccountRequired, disabled: disabledFlag, error: this.state.errors['landLinkAccount'] },
					postToLandLinkField]
			},
			{
				formGroups: [imageChargeMethodField,
					imageRetrievalField,
					previewRetrievalField
				]
			}]

		if (this.props.isTorrens === true) {
			formGroupRows.push({
				formGroups: [certificateChargeMethodField,
					certificateRetrievalField,
					certificatePreviewRetrievalField
				]
			})
		}

		formGroupRows.push(

			{
				formGroups: [{ id: 'address', label: 'Address', fieldType: 'text', value: this.state.address, required: false, disabled: disabledFlag, error: this.state.errors['address'] }]
			},
			{
				formGroups: [{ id: 'city', label: 'City', fieldType: 'text', value: this.state.city, required: false, disabled: disabledFlag, error: this.state.errors['city'] },
				{ id: 'state', label: 'State', fieldType: 'select', value: this.state.state, options: usStates, required: false, disabled: disabledFlag },
				{ id: 'zip', label: 'Zip (ex. 55111)', fieldType: 'text', value: this.state.zip, required: false, disabled: disabledFlag }]
			},
			{
				formGroups: [{ id: 'phone', label: 'Phone (ex. (612) 555-1234)', fieldType: 'tel', value: this.state.phone, required: false, disabled: disabledFlag },
				{ id: 'email', label: 'Email', fieldType: 'email', value: this.state.email, required: false, disabled: disabledFlag, error: this.state.errors['email'] }]
			},
			{
				formGroups: [needsMonthlyRenewalField,
					needsMonthlyRenewalJobField,
					lastMonthlyRenewalField
				]
			}

		)

		// If new account, show the Account Name  in the entry form.
		if (this.state.id === 0) {
			formGroupRows.splice(0, 0,	{formGroups: [{ id: 'name', label: 'Account Name', fieldType: 'text', required: true }]} );
		}
		
		let changeStatusLabel = 'Activate';
		if (this.state.status === 'A') {
			changeStatusLabel = 'Deactivate';
		}

		let pageButtons = [];
		if (this.state.needsMonthlyRenewal) {
			pageButtons.push( {label:'Run Monthly Renewal', onClickAction: this.handleMonthlyRenewal, className: 'btn btn-secondary btn-sm'} );
		}
		pageButtons.push( {label:changeStatusLabel, onClickAction: this.handleChangeAccountStatus, className: 'btn btn-secondary btn-sm'} );
		pageButtons.push( {label:'Delete', onClickAction: this.showDeleteConfirmation, className: 'btn btn-danger btn-sm'} );

		const formProps = {
			componentName: "AccountEntry",
			pageHeading: "Account (" + (this.state.id ? this.state.name + ")" : 'new*)'), 
			onChange: this.handleChange,
			onSubmit: this.handleSubmit,
			onCancel: this.handleCancel,
			formGroupRows: formGroupRows,
			loadingIndicator: this.state.loadingStatus === LOADING_STATUES.LOADING,
			pageButtons: (this.state.id ? pageButtons : null) // Existing account, show page buttons.  New account, don't.
		}

		return (
			<>
				<FormTemplate {...formProps} />
				<Loading loadingIndicator={this.state.loadingStatus === LOADING_STATUES.LOADING } />
				<Confirmation show={this.state.showDeleteConfirmationDialog} cancelConfirmation={this.hideDeleteConfirmation} proceedConfirmation={this.handleDeleteAccount}  question={"'Delete account and its users?"} />
				<Alert show={this.state.showAlert} hideAlert={this.hideAlert} message={this.state.alertMessage} type={this.state.alertType} />
			</>
		)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountEntry);
