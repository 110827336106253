// Trim all fields except for username and password. 
// Let validation errors out leading and trailing spaces on username and password.
export const trimUserFields = (user) => {
    let trimmedUser = {};

    // First Name
    if (user.firstName) {
        let firstName = user.firstName.trim();
        firstName = {firstName};
        trimmedUser = {...trimmedUser, ...firstName};
    }

    // Last Name
    if (user.lastName) {
        let lastName = user.lastName.trim();
        lastName = {lastName};
        trimmedUser = {...trimmedUser, ...lastName};
    }

    // Company
    if (user.company) {
        let company = user.company.trim();
        company = {company};
        trimmedUser = {...trimmedUser, ...company};
    }

    // Address
    if (user.address) {
        let address = user.address.trim();
        address = {address};
        trimmedUser = {...trimmedUser, ...address};
    }

    // City
    if (user.city) {
        let city = user.city.trim();
        city = {city};
        trimmedUser = {...trimmedUser, ...city};
    }

    // Email
    if (user.email) {
        let email = user.email.trim();
        email = {email};
        trimmedUser = {...trimmedUser, ...email};
    }
    return trimmedUser;
}

// This function is used by different user forms in the app.
// Different forms may or may not contain a certain field. 
// For example, you can't change the username on an existing user, so its form doesn't have username.
// Hence, the checks for undefined to make sure the field is in the user object.
export const validateUser = (user, validateUsername = true, validatePassword = true) => {
    let errors = {};

    // Username
    if (validateUsername && user.username !== undefined) { // make sure it's defined
        if (!user.username) { // make sure it's not blank or null.
            errors["username"] = "Required.";
            return errors;
        }

        if (user.username && user.username.length < 8) {
            errors["username"] = "At least 8 characters long.";
            return errors;
        }

        if (user.username && user.username.length > 50) {
            errors["username"] = "Maximum 50 characters long.";
            return errors;
        }

        if (user.username.search(/^[0-9a-zA-Z._@]+$/) === -1) {
                errors["username"] = "Letters, numbers, underscores, dots and @ only.";
                return errors;
        }

        if (user.username.startsWith(".") || user.username.startsWith("@") || user.username.startsWith("_")) {
            errors["username"] = "Must start with a number or letter.";
            return errors;
        }

        if (user.username.endsWith(".") || user.username.endsWith("@") || user.username.endsWith("_")) {
            errors["username"] = "Must end with a number or letter.";
            return errors;
        }

        if (user.username.includes("..") || user.username.includes("__") || user.username.includes("@@") ||
            user.username.includes("._") || user.username.includes("_.") || 
            user.username.includes(".@") || user.username.includes("@.") || 
            user.username.includes("_@") || user.username.includes("@_") ) {
            errors["username"] = "No consecutive special characters.";
            return errors;
        }
    }
    
    // Password
    if (validatePassword && user.password !== undefined) {
        if (!user.password) {
            errors["password"] = "Required.";
            return errors;
        }

        if (!user.passwordConfirm) {
            errors["passwordConfirm"] = "Required.";
            return errors;
        }

        if (user.password && user.password.length < 8) {
            errors["password"] = "At least 8 characters.";
            return errors;
        }

        if (user.password.length > 64) {
            errors["password"] = "64 characters maximum.";
            return errors;
        }

        if (user.password.search(/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&])[0-9a-zA-Z!@#$%^&]{8,64}$/) === -1) {
            errors["password"] = "Must contain a letter, a number and a special character. Special characters are !@#$%^&";
            return errors;
        }

        if (user.password !== user.passwordConfirm) {
            errors["password"] = "Mismatched passwords.";
            return errors;
        }
    }

    // First Name
    if (user.firstName !== undefined) {
        if (!user.firstName) {
            errors["firstName"] = "Required.";
            return errors;
        }

        if (user.firstName && user.firstName.length > 30) {
            errors["firstName"] = "30 characters max.";
            return errors;
        }
    }

    // Last Name
    if (user.lastName !== undefined) {
        if (!user.lastName) {
            errors["lastName"] = "Required.";
            return errors;
        }

        if (user.lastName && user.lastName.length > 30) {
            errors["firstName"] = "30 characters max.";
            return errors;
        }
    }

    // Name - Efile
    if (user.name !== undefined) {
        if (!user.name) {
            errors["name"] = "Required.";
            return errors;
        }

        if (user.name && user.name.length > 150) {
            errors["name"] = "150 characters max.";
            return errors;
        }
    }

    // Company
    if (user.company !== undefined) {
        if (user.company && user.company.length > 60) {
            errors["company"] = "60 characters max.";
            return errors;
        }
    }

    // Address
    if (user.address !== undefined) {
        if (user.address && user.address.length > 50) {
            errors["address"] = "50 characters max.";
            return errors;
        }
    }

    // Address1 - Efile
    if (user.address1 !== undefined) {
        if (user.address1 && user.address1.length > 50) {
            errors["address1"] = "50 characters max.";
            return errors;
        }
    }

    // Address2 - Efile
    if (user.address2 !== undefined) {
        if (user.address2 && user.address2.length > 50) {
            errors["address2"] = "50 characters max.";
            return errors;
        }
    }

    // City
    if (user.city !== undefined) {
        if (user.city && user.city.length > 50) {
            errors["city"] = "50 characters max.";
            return errors;
        }
    }

    // Email
    if (user.email !== undefined) {
        if (user.email && user.email.length > 50) {
            errors["email"] = "50 characters max.";
            return errors;
        }
    }

    return errors;
}


export const validatePassword = (passwordReset) => {
    let errors = {};
    
    // Password
    if (passwordReset.password !== undefined) {
        if (!passwordReset.password) {
            errors["password"] = "Required.";
            return errors;
        }

        if (!passwordReset.passwordConfirm) {
            errors["passwordConfirm"] = "Required.";
            return errors;
        }

        if (passwordReset.password && passwordReset.password.length < 8) {
            errors["password"] = "At least 8 characters.";
            return errors;
        }

        if (passwordReset.password.length > 64) {
            errors["password"] = "64 characters maximum.";
            return errors;
        }

        if (passwordReset.password.search(/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&])[0-9a-zA-Z!@#$%^&]{8,64}$/) === -1) {
            errors["password"] = "Must contain a letter, a number and a special character.";
            return errors;
        }

        if (passwordReset.password !== passwordReset.passwordConfirm) {
            errors["password"] = "Mismatched passwords.";
            return errors;
        }
    }




    return errors;
}