import { combineReducers } from "redux";

import imageContentReducer from "./imageContentReducer"; // ??
import countyConfigReducer from "./countyConfigReducer"; // ??

// Object Types
import auditLogTypesAllReducer from "./auditLogTypesAllReducer";
import auditTransactionTypesAllReducer from "./auditTransactionTypesAllReducer";
import auditLogTypesReducer from "./auditLogTypesReducer";
import auditTransactionTypesReducer from "./auditTransactionTypesReducer";
import accountTypesReducer from "./accountTypesReducer";
import bookTypesReducer from "./bookTypesReducer";
import documentTypesReducer from "./documentTypesReducer";
import documentTypesWithBlankReducer from "./documentTypesWithBlankReducer";
import instrumentCodesReducer from "./instrumentCodesReducer";
import instrumentCodesWithBlankReducer from "./instrumentCodesWithBlankReducer";
import instrumentGroupsReducer from "./instrumentGroupsReducer";
import otherLotCodesReducer from "./otherLotCodesReducer";
import paymentMethodTypesAllReducer from "./paymentMethodTypesAllReducer";
import paymentMethodTypesNonALaCarteReducer from "./paymentMethodTypesNonALaCarteReducer";
import platsReducer from "./platsReducer";
import platsByNumberReducer from "./platsByNumberReducer";
import platNamesReducer from "./platNamesReducer";
import platNumbersReducer from "./platNumbersReducer";
import userTypesReducer from "./userTypesReducer";
import dataSourceTypesReducer from "./dataSourceTypesReducer";

// LL County Config
import landlinkConfigReducer from './landlinkConfigReducer';

// LS County Config
import countyConfigFlatFeeReducer from "./countyConfigFlatFeeReducer";
import countyConfigFlatFeeOverrideUISettingsReducer from "./countyConfigFlatFeeOverrideUISettingsReducer";
import countyConfigReportsReducer from "./countyConfigReportsReducer"; 
import countyConfigEfileReducer from "./countyConfigEfileReducer"; 
import countyConfigEscrowReducer from "./countyConfigEscrowReducer"; 
import countyConfigCreditCardExtraReducer from "./countyConfigCreditCardExtraReducer"; 
import countyConfigLanguagesReducer from "./countyConfigLanguagesReducer"; 
import countyConfigNotableDatesReducer from "./countyConfigNotableDatesReducer"; 
import countyConfigSearchReducer from "./countyConfigSearchReducer"; 
import countyConfigSearch2Reducer from "./countyConfigSearch2Reducer"; 
import countyConfigCommonReducer from "./countyConfigCommonReducer";
import countyConfigUserAccessReducer from "./countyConfigUserAccessReducer";
import countyConfigLoginRegistrationReducer from "./countyConfigLoginRegistrationReducer";

// As defined in LS Server product.propertes
import productPropertiesReducer from "./productPropertiesReducer";

// LS Lockout
import countyConfigUserLockoutReducer from "./countyConfigUserLockoutReducer";

// Current User
import currentUserReducer from "./currentUserReducer";

// Criteria
import reportCriteriaReducer from "./reportCriteriaReducer";
import searchCriteriaReducer from "./searchCriteriaReducer";

// History
import searchHistoryReducer from "./searchHistoryReducer";

// Search Results and Details
import searchDetailsReducer from "./searchDetailsReducer";
import searchDetailsGrantorGranteeBookReducer from "./searchDetailsGrantorGranteeBookReducer";
import searchDetailsTaxLienReducer from "./searchDetailsTaxLienReducer";
import searchDetailsTorrensCertificateReducer from "./searchDetailsTorrensCertificateReducer";
import searchDetailsTractBookReducer from "./searchDetailsTractBookReducer";

import searchDetailsGrantorsGranteesPaginationReducer from './searchDetailsGrantorsGranteesPaginationReducer';
import searchDetailsLegalDescriptionsPaginationReducer from './searchDetailsLegalDescriptionsPaginationReducer';
import searchDetailsReferenceDocumentsPaginationReducer from './searchDetailsReferenceDocumentsPaginationReducer';
import searchDetailsTorrensCertificatesPaginationReducer from './searchDetailsTorrensCertificatesPaginationReducer'
import searchDetailsTaxpayersPaginationReducer from './searchDetailsTaxpayersPaginationReducer'
import searchDetailsReleasesPaginationReducer from './searchDetailsReleasesPaginationReducer'
import searchDetailsRevisionsPaginationReducer from './searchDetailsRevisionsPaginationReducer'
import searchSequenceStartedReducer from "./searchSequenceStartedReducer"
import searchSequenceRewindReducer from "./searchSequenceRewindReducer"
import searchResultsTableSelectedRowReducer from "./searchResultsTableSelectedRowReducer"
import searchResultsFilterAppliedFilter from './searchResultsFilterAppliedFilter'

import searchResultsReducer from "./searchResultsReducer"; 

import searchResultsSortReducer from './searchResultsSortReducer';

import searchResultsTableLoadingReducer from './searchResultsTableLoadingReducer';

import searchResultsFilterReducer from './searchResultsFilterReducer';
import searchResultsFilterGrantorGranteeBookReducer from './searchResultsFilterGrantorGranteeBookReducer';
import searchResultsFilterTaxLiensReducer from './searchResultsFilterTaxLiensReducer';
import searchResultsFilterTorrensReducer from './searchResultsFilterTorrensReducer';
import searchResultsFilterTractBookReducer from './searchResultsFilterTractBookReducer';

// Account and User Lists
import accountListFiltersReducer from './accountListFiltersReducer';
import userListFiltersReducer from './userListFiltersReducer';

// Paginators
import accountsPaginationReducer from './accountsPaginationReducer';
import accountSelectionListPaginationReducer from './accountSelectionListPaginationReducer';
import platSelectionListPaginationReducer from './platSelectionListPaginationReducer'
import searchResultsPaginationReducer from './searchResultsPaginationReducer';
import searchResultsPageVisitedReducer from './searchResultsPageVisitedReducer';
import userSelectionListPaginationReducer from './userSelectionListPaginationReducer';
import usersPaginationReducer from './usersPaginationReducer';

// Search page, Report page and Control Panel page selections
import searchTypeReducer from "./searchTypeReducer";
import reportTypeReducer from "./reportTypeReducer";
import controlPanelTypeReducer from "./controlPanelTypeReducer";

// Side Navigation
import sideNavigationReducer from "./sideNavigationReducer";

// Server Product Versions
import serverProductVersionsReducer from "./serverProductVersionsReducer";

// Notable Dates
import notableDatesReducer from "./notableDatesReducer";

const appReducer = combineReducers({

	countyConfigReducer, // ??
	imageContentReducer, // ??
	
	// Object Types
	auditLogTypesReducer,
	auditLogTypesAllReducer,
	auditTransactionTypesReducer,
	auditTransactionTypesAllReducer,
	accountTypesReducer,
	bookTypesReducer,
	documentTypesReducer,
	documentTypesWithBlankReducer,
	instrumentCodesReducer,
	instrumentCodesWithBlankReducer,
	instrumentGroupsReducer,
	otherLotCodesReducer,
	paymentMethodTypesAllReducer,
	paymentMethodTypesNonALaCarteReducer,
	platsReducer,
	platsByNumberReducer,
	platNamesReducer,
	platNumbersReducer,
	userTypesReducer,
	dataSourceTypesReducer,

	// LL County Config
	landlinkConfigReducer,
	
	// LS Control Panel
	countyConfigCommonReducer,
	countyConfigCreditCardExtraReducer,
	countyConfigEfileReducer,
	countyConfigEscrowReducer,
	countyConfigFlatFeeReducer,
	countyConfigFlatFeeOverrideUISettingsReducer,
	countyConfigLanguagesReducer,
	countyConfigNotableDatesReducer,
	countyConfigSearchReducer,
	countyConfigSearch2Reducer,
	countyConfigReportsReducer,
	countyConfigUserAccessReducer,
	countyConfigLoginRegistrationReducer,

	// LS Server product.properties
	productPropertiesReducer,

	// LS Lockout
	countyConfigUserLockoutReducer,

	// Current User
	currentUserReducer,

	// Criteria
	searchCriteriaReducer,
	reportCriteriaReducer,

	// History
	searchHistoryReducer,

	// Search Results and Details
	searchDetailsReducer,
	searchDetailsGrantorGranteeBookReducer,
	searchDetailsTaxLienReducer,
	searchDetailsTorrensCertificateReducer,
	searchDetailsTractBookReducer,
	searchDetailsGrantorsGranteesPaginationReducer,
	searchDetailsLegalDescriptionsPaginationReducer,
	searchDetailsReferenceDocumentsPaginationReducer,
	searchDetailsTorrensCertificatesPaginationReducer,
	searchDetailsTaxpayersPaginationReducer,
	searchDetailsReleasesPaginationReducer,
	searchDetailsRevisionsPaginationReducer,
	searchResultsReducer,
	searchResultsPaginationReducer,
	searchResultsSortReducer,
	searchResultsTableLoadingReducer,
	searchSequenceStartedReducer,
	searchResultsTableSelectedRowReducer,
	searchSequenceRewindReducer,

	searchResultsPageVisitedReducer,

	searchResultsFilterReducer,
	searchResultsFilterGrantorGranteeBookReducer,
	searchResultsFilterTaxLiensReducer,
	searchResultsFilterTorrensReducer,
	searchResultsFilterTractBookReducer,
	searchResultsFilterAppliedFilter,

	// Account and User Selectors
	accountListFiltersReducer,
	userListFiltersReducer,

	// Account, Plat and User Lists
	accountsPaginationReducer,
	accountSelectionListPaginationReducer,
	platSelectionListPaginationReducer,
	userSelectionListPaginationReducer,
	usersPaginationReducer,

	// Search page, Report page and Control Panel page selections
	controlPanelTypeReducer,
	reportTypeReducer,
	searchTypeReducer,

	// Side Navigation
	sideNavigationReducer,

	// Server Product Versions
	serverProductVersionsReducer,

	// Notable Dates
	notableDatesReducer

});

const rootReducer = (state, action) => {
	if (action.type === 'USER_LOGOUT') { // LOGOUT_USER
		return appReducer(undefined, action)
	}
	return appReducer(state, action)
}

export default rootReducer;