import React, { Component } from 'react';
import { Modal, Button, Row, Col, Container } from 'react-bootstrap';
import { parse } from 'query-string';

import configData from "../../config/config.json";

const PREFIX = 'CreditCardWindow: ';

// Class notes.
//
// - props.creditCardWindow.*
// - action ( 'show' or any )
// - event.backClicked
// - event.closeClicked
// - event.continueClicked
// - show
// - version
class CreditCardWindow extends Component {
    
  constructor(props) {
    super(props);

    console.log(PREFIX + "constructed with props", props);

    this.state = {
    };


    this.authFormRef = React.createRef();

    this.version = 0;

    this.updateStateFromProps(true, false);
    console.log(PREFIX + 'construction complete');
  }
  
  handleBackClick = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.state.event.backClicked({});
  }
     
  handleCloseClick = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.state.event.closeClicked();
  }

  handleAuthorizeDotNetPayClicked = (json) => {
    this.state.event.payClicked(json);
  }

  handleAuthorizeDotNetCancelClicked = (json) => {
    this.state.event.closeClicked();
  }
  
  updateStateFromProps = (createIfMissing, callSetState) => {
    let source = this.props.creditCardAuthWindow;

    if (!source) {
      if (!createIfMissing) return;

      source = {
        action: '',
        event: {},
        message: '',
        show: false,
        previousShow: false,
        version: (this.version < 1) ? 1 : this.version + 1
      }
    }

    if (source.version <= this.version) return;
    let newState = callSetState ? {} : this.state;

    newState.show = source.show;
    this.version = source.version;

    if (source.action !== 'show') {
      newState.event = source.event;
      newState.message = source.message;
      newState.token = source.token;
    }

    if (callSetState) {
      this.updateState(newState);
    }
  }
  
  updateState = (newState) => {
    console.log(PREFIX + 'setState', newState);
    this.setState(newState);
  }

  isValidMessage(o) {
    return typeof o === 'object' && o !== null
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = configData.AUTHORIZE_ACCEPTJS_URL;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.creditCardAuthWindow.show === undefined) {
      // nothing to display
      return;
    } else if (this.props.creditCardAuthWindow.show === prevProps.creditCardAuthWindow.show) {
      // no change
      return;
    } else {
      // changing
    }

    if (this.state.show && !this.state.previousShow) {
      this.setState( {previousShow: this.state.show} );
      this.submitAuthorizeDotNetForm();
      window.AuthorizeNetIFrame = {
        onReceiveCommunication: queryStr => {
          console.log(PREFIX + 'received communication: ', queryStr);
  
          const message = parse(queryStr);
          if (this.isValidMessage(message)) {
            switch (message.action) {
              case 'transactResponse':
                const json = JSON.parse(message.response);
                console.log(PREFIX + 'processing transactRepsponse: ', json);
                this.handleAuthorizeDotNetPayClicked(json);
                break;
  
              case 'resizeWindow':
                break;
  
              case 'cancel':
                this.handleAuthorizeDotNetCancelClicked();
                break;
              default:
                console.log(PREFIX + 'unrecognized message action from Authorize.net:[' + message.action + ']');
                this.handleAuthorizeDotNetCancelClicked();
            };
          }
        },
      };
    } else if (!this.state.show && this.state.previousShow) {
      this.setState( {previousShow: this.state.show} );

      if (window.AuthorizeNetIFrame) {
        delete window.AuthorizeNetIFrame;
      }
    }
  }

  submitAuthorizeDotNetForm() {
    if (this.authFormRef.current) {
      this.authFormRef.current.submit();
    }
  }

  render() {
    console.log(PREFIX + 'rendering state version ' + this.version);

    this.updateStateFromProps(false, false);

    console.log(PREFIX + 'state: ', this.state);
    console.log(PREFIX + 'props: ', this.props);

    return (
      <Modal show={this.state.show} onHide={this.handleCloseClick} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Credit Card Preauthorization
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{borderTop: '2px solid black', borderBottom: '2px solid black',background: '#408CC7' }}>
          <iframe title='Credit Card Preauthorization for Images' id="load_payment" name="load_payment" width="100%" height="500px" scrolling="yes" />
          <form ref={this.authFormRef} id="send_hptoken" action={configData.AUTHORIZE_PAYMENT_URL} method="post" target="load_payment">
            <input type="hidden" name="token" value={this.state.token} />
          </form>
        </Modal.Body>
        <Modal.Footer >
          <Container >
            <Row style={{ margin: 5 }} >
              <Col md='3' className='align-self-end' style={{ textAlign: 'left' }}>
                <Button variant="secondary" onClick={this.handleBackClick}>Back</Button>
              </Col>
              <Col md='9' className='align-self-end' style={{ textAlign: 'right' }}>
                <Button variant="secondary" onClick={this.handleCloseClick}>Cancel</Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default CreditCardWindow;
